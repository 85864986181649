var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "register-funnel login-funnel" }, [
    _c("div", { staticClass: "register-funnel__main-block" }, [
      _c("div", { staticClass: "register-funnel__main-block__inner" }, [
        _c(
          "div",
          { staticClass: "register-funnel__main-block__heading" },
          [
            !_vm.isMobileApp
              ? [
                  _vm._m(0),
                  _c(
                    "div",
                    {
                      staticClass: "register-funnel__main-block__heading__auth",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "register-funnel__main-block__heading__auth__text",
                        },
                        [_vm._v(_vm._s(_vm.$t("login.dontHaveAnAccount")))]
                      ),
                      _c("img", {
                        staticClass:
                          "register-funnel__main-block__heading__auth__icon",
                        attrs: {
                          src: require("@/assets/images/register-funnel/lock.png"),
                          alt: "Secure",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "register-funnel__main-block__heading__auth__link",
                          on: { click: _vm.registerUser },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("login.signUp")) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              : _vm._e(),
            _vm.isMobileApp
              ? _c(
                  "div",
                  {
                    staticClass:
                      "register-funnel__main-block__heading__logo-wrapper",
                  },
                  [
                    _c("img", {
                      staticClass:
                        "register-funnel__main-block__heading__logo-img",
                      attrs: {
                        src: require("@/assets/images/register-funnel/vega_logo.svg"),
                        alt: "Logo",
                      },
                    }),
                  ]
                )
              : _vm._e(),
          ],
          2
        ),
        _c("div", { staticClass: "register-funnel__main-block__body" }, [
          _c("div", { staticClass: "register-funnel__main-block__flow" }, [
            _c(
              "div",
              { staticClass: "register-funnel__main-block__flow__heading" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "register-funnel__main-block__flow__heading__title",
                  },
                  [_vm._v(_vm._s(_vm.$t("login.welcomeBack")))]
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "register-funnel__main-block__flow__content" },
              [
                _c("funnel-social-login", { attrs: { type: "login" } }),
                _c(
                  "div",
                  {
                    staticClass:
                      "register-funnel__main-block__flow__content__separator",
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "register-funnel__main-block__flow__content__separator__label",
                      },
                      [_vm._v(_vm._s(_vm.$t("vue.or").toLowerCase()))]
                    ),
                  ]
                ),
                _c("form", [
                  _c(
                    "div",
                    {
                      staticClass:
                        "register-funnel__main-block__flow__content__form",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "register-funnel__main-block__flow__content__form__fields",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "register-funnel__main-block__flow__content__form__fields__field",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "register-funnel__main-block__flow__content__form__fields__field__inner",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.email,
                                        expression: "email",
                                      },
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required|email",
                                        expression: "'required|email'",
                                      },
                                    ],
                                    ref: "email",
                                    staticClass:
                                      "register-funnel__main-block__flow__content__form__fields__field__input",
                                    attrs: {
                                      type: "email",
                                      "data-vv-validate-on": "blur",
                                      name: _vm.$t("inputs.email"),
                                      "label-placeholder":
                                        _vm.$t("inputs.email"),
                                    },
                                    domProps: { value: _vm.email },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.email = $event.target.value
                                      },
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "register-funnel__main-block__flow__content__form__fields__field__label",
                                      class: {
                                        "register-funnel__main-block__flow__content__form__fields__field__label--moved":
                                          _vm.email && _vm.email.length > 0,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.email.focus()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.$t("inputs.enterYourEmail")
                                          ) +
                                          "\n                      "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm.errors.first(_vm.$t("inputs.email"))
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-danger text-lg flex items-center",
                                      staticStyle: { "margin-top": "10px" },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          src: require("@/assets/images/pages/login/error-icon.svg"),
                                        },
                                      }),
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first(
                                            _vm.$t("inputs.email")
                                          )
                                        ) + "\n                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "register-funnel__main-block__flow__content__form__fields__field",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "register-funnel__main-block__flow__content__form__fields__field__inner",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.password,
                                        expression: "password",
                                      },
                                      {
                                        name: "validate",
                                        rawName: "v-validate.continues",
                                        value: "required|min:6",
                                        expression: "'required|min:6'",
                                        modifiers: { continues: true },
                                      },
                                    ],
                                    ref: "password",
                                    staticClass:
                                      "register-funnel__main-block__flow__content__form__fields__field__input",
                                    attrs: {
                                      type: "password",
                                      autocomplete: "on",
                                      name: _vm.$t("inputs.password"),
                                      "label-placeholder":
                                        _vm.$t("inputs.password"),
                                    },
                                    domProps: { value: _vm.password },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.password = $event.target.value
                                      },
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "register-funnel__main-block__flow__content__form__fields__field__label",
                                      class: {
                                        "register-funnel__main-block__flow__content__form__fields__field__label--moved":
                                          _vm.password &&
                                          _vm.password.length > 0,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.password.focus()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(_vm.$t("inputs.password")) +
                                          "\n                      "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm.errors.first(_vm.$t("inputs.password"))
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-danger text-lg flex items-center",
                                      staticStyle: { "margin-top": "10px" },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          src: require("@/assets/images/pages/login/error-icon.svg"),
                                        },
                                      }),
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first(
                                            _vm.$t("inputs.password")
                                          )
                                        ) + "\n                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "register-funnel__main-block__flow__content__form__link-wrapper",
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "register-funnel__main-block__flow__content__form__link",
                              attrs: { to: "/pages/forgot-password" },
                            },
                            [_vm._v(_vm._s(_vm.$t("vue.forgotPassword")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "register-funnel__main-block__flow__content__next-button-wrapper",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "register-funnel__main-block__flow__content__next-button",
                    on: { click: _vm.login },
                  },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("vue.login")) +
                        "\n\n              "
                    ),
                    _c("img", {
                      staticClass:
                        "register-funnel__main-block__flow__content__next-button__icon",
                      attrs: {
                        src: require("@/assets/images/register-funnel/forward-arrow.png"),
                        alt: "Secure",
                      },
                    }),
                  ]
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "register-funnel__main-block__heading__logo-wrapper",
        attrs: { href: "https://www.letsconnect.at/" },
      },
      [
        _c("img", {
          staticClass: "register-funnel__main-block__heading__logo-img",
          attrs: {
            src: require("@/assets/images/register-funnel/vega_logo.svg"),
            alt: "Logo",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }